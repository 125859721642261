import React from 'react'
import Layout from '../components/layout/layout'
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTiktok, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons";

interface Props {
  location: Location
}

const Profile: React.FC<Props> = ({ location }: Props) => {
  return (
    <Layout>
      <div>
        <section className="text-center">
          <div className="container">
            <StaticImage 
              src="../images/wb_desire_s_512.png"
              alt="bobacritic"
              className="rounded-circle"
              width={200}
              height={200}
            />
            <h1>bobacritic</h1>
            <p className="lead text-muted">All things boba.</p>
            <div className="container w-50">
              <div className="row">
                <a
                  href="https://twitter.com/bobacritic"
                  className="col"
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a
                  href="https://www.instagram.com/boba_critic/"
                  className="col"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a
                  href="https://www.tiktok.com/@bobacritic"
                  className="col"
                >
                  <FontAwesomeIcon icon={faTiktok} />
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Profile